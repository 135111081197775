<template>
	<div class="container">
		<!-- 头部 -->
		<div class="top-nav-bar">
			<TopNavBar>{{ title }}</TopNavBar>
		</div>

		<!-- 列表 -->

		<div class="scroll-box">
			<div class="content">
				<div class="list">
					<!-- 日常 -->
					<template v-if="currentType == 0 && dataList.length != 0">
						<Daily :dataList="dataList" :fileList="fileList"></Daily>
					</template>

					<!-- 烟火 -->
					<template v-if="currentType == 1 && dataList.length != 0">
						<SetOff :dataList="dataList" :fileList="fileList"></SetOff>
					</template>
					<!-- 图片 -->
					<template v-if="currentType == 2 && dataList.length != 0">
						<div v-for="(item, index) in dataList" :key="item.uuid" class="img-item">
							<div class="top">
								<div class="left"><img :src="$store.state.deLogoSquare" alt="" /></div>
								<div class="right">
									<div class="item-content">
										<div class="title">
											<span>{{ item.title }}</span>
											<img v-if="isNew(item.optionDate)" src="~@/assets/img/proDetails/new.png" alt="" />
											<img v-else src="~@/assets/img/proDetails/hot.png" alt="" />
										</div>
										<div class="context">{{ item.context }}</div>
									</div>
									<div v-if="currentType == 2 && fileList.length != 0" class="img-box">
										<van-image
											v-for="(item, i) in fileList[index]"
											:key="i"
											style="margin: 0 3px 3px 0"
											width="86"
											height="86"
											fit="cover"
											:src="item"
											alt=""
											@click="imagePreview(i, fileList[index])"
										/>
									</div>
								</div>
							</div>

							<div class="item-footer">
								<span class="optionDate">{{ item.optionDate }}</span>
								<!-- <span class="clickCount">观看次数：{{ item.clickCount }}次</span> -->
							</div>
						</div>
					</template>

					<!-- 水印 -->
					<WaterMark />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ImagePreview } from 'vant';
import TopNavBar from './model/TopNavBar.vue';
import scroll from '@c/scroll/Scroll.vue';
import { getSharingSpace, getFileList, clickAddOne } from '@/api';
import Daily from './model/Daily.vue'
import SetOff from './model/SetOff.vue'
export default {
	name: 'SharingSpace',
	components: {
		TopNavBar,
		scroll,
		Daily,
		SetOff
	},

	data() {
		return {
			fileList: [],
			dataList: [],
			currentType: 0,
			title: '燃放日常',
			videoList: [],
			mutex: false,
			fileListPic: [],
			videoElement: null,
			isPlay: false, // 当前视频是否正在播放
			activeIndex: -1
		};
	},
	computed: {
		$video() {
			return this.$refs.vueMiniPlayer.$video;
		},
		optionType() {
			const { type } = this.$route.query;
			this.currentType = parseInt(type);
			let optionType = '';
			switch (this.currentType) {
				case 0:
					optionType = 'OPTION_DAY';
					this.title = '燃放日常';
					break;
				case 1:
					optionType = 'OPTION_VIDEO';
					this.title = '焰火视频';
					break;
				case 2:
					optionType = 'OPTION_PIC';
					this.title = '精彩图文';
					break;
			}
			return optionType;
		}
	},

	created() {
		const { type } = this.$route.query;
		this.currentType = parseInt(type);
		this.getSharingSpace();
	},
	mounted() {
		this.videoElement = document.getElementsByTagName('video'); // 获取页面上所有的video对象
	},
	methods: {
		// 半年以内的算新,半年以上的算热
		play(index) {
			this.activeIndex = index;
			const videoElement = this.videoElement;
			if (videoElement && videoElement.length > 0) {
				for (let i = 0; i < videoElement.length; i++) {
					if (i === index) {
						this.videoElement[i].play();
					} else {
						this.videoElement[i].pause();
					}
				}
			}
		},
		handleFullscreen() {},
		onPause(index) {
			this.activeIndex = -1;
		},
		onPauseIndex(index){
		},
		onVideoPlay(evt, uuid, index) {
			this.activeIndex = index;
			const videoElement = this.videoElement;
			if (videoElement && videoElement.length > 0) {
				for (let i = 0; i < videoElement.length; i++) {
					if (i === index) {
						this.videoElement[i].play();
					} else {
						this.videoElement[i].pause();
					}
				}
			}

			let time = evt?.srcElement?.currentTime;
			if (time == 0) {
				clickAddOne({ uuid });
			}
		},
		isNew(date) {
			const stamp1 = new Date(date).getTime();
			const stamp2 = new Date().getTime();
			const s = Math.floor(Math.abs(stamp1 - stamp2) / 1000);
			const y = Math.floor(s / 3600 / 24 / 365);
			const m = Math.floor(((s / 3600 / 24) % 365) / 30);
			if (y > 1) {
				return false;
			} else if (y < 1 && m > 6) {
				return false;
			} else {
				return true;
			}
		},
		imagePreview(index, images) {
			ImagePreview({
				images,
				startPosition: index
			});
		},

		// 获取列表
		getSharingSpace() {
			const params = {
				page: 0,
				pageSize: 0,
				shopUuid: this.$store.state.shopUuid,
				optionType: this.optionType
			};
			getSharingSpace(params).then(res => {
				if (res.state == 100) {
					this.dataList = [...res.items];
					this.dataList.forEach((item, index) => {
						this.getFileList(item.uuid, index);
					});
					// this.$nextTick(() => {
					//   this.$refs.scroll.refresh()
					// })
				}
			});
		},

		getFileList(uuid, index) {
			const params = {
				page: 0,
				pageSize: 0,
				parentUuid: uuid
			};
			getFileList(params).then(res => {
				if (res.state == 100) {
					if (this.currentType == 2) {
						// 图片不作处理
						const arr = res.items.map(item => item.filePath);
						this.$set(this.fileList, index, arr);
					} else {
						const fileObj = {};
						res.items.map(item => {
							if (item['typeName'] && item['typeName'].endsWith('_PIC')) {
								fileObj['poster'] = item['filePath'];
							} else {
								fileObj['video'] = item['filePath'];
							}
						});
						this.$set(this.fileList, index, fileObj);
					}
					// this.$nextTick(() => {
					//   this.$refs.scroll.refresh()
					// })
				}
			});
		},
		// 监听滚动事件
		contentScroll(position) {
			// 1.判断BackTop是否显示
			// this.isShowBackTop = -position.y > BACKTOP_DISTANCE;
			// 2.觉得tabControl是否吸顶
			// this.isTabFixed = -position.y > this.tabControl;
		},
		// 加载更多
		loadMore() {
			// this.getHomeGoods(this.currentType);
		}
	}
};
</script>

<style lang="less" scoped>
video {
	object-fit: cover;
	outline: none;
}
video::-webkit-media-controls-fullscreen-button {
	display: none;
}
//播放按钮
video::-webkit-media-controls-play-button {
	display: none;
}
//进度条
video::-webkit-media-controls-timeline {
	display: none;
}
//观看的当前时间
video::-webkit-media-controls-current-time-display {
	display: none;
}
//剩余时间
video::-webkit-media-controls-time-remaining-display {
	display: none;
}
//音量按钮
video::-webkit-media-controls-mute-button {
	display: none;
}
video::-webkit-media-controls-toggle-closed-captions-button {
	display: none;
}
//音量的控制条
video::-webkit-media-controls-volume-slider {
	display: none;
}
//所有控件
video::-webkit-media-controls-enclosure {
	display: none;
}
.play-btn {
	// width: 150px;
	// height: 150px;
	width: 55px;
	height: 55px;
	z-index: 3;
}
.container {
	height: 100vh;
	overflow-y: scroll;
	.video {
		position: relative;
		.video_box {
			/*width: 158px;*/
			max-height: 211px;
			background: #fff !important;
		}
	}
	.float-wrapper {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.img-container {
			position: fixed;
			left: 50%;
			bottom: 50%;
			transform: translate(-50%, 50%);
			/*bottom: 0;*/
			/*right: 0;*/
			/*margin-right: 80px;*/
			/*margin-bottom: 240px;*/
			width: 240px;
			height: 240px;
			display: flex;
			align-items: center;
			justify-content: center;
			overflow: hidden;
			transition: all 0.9s;
			.preview-img {
				/*width: 100%;*/
				height: 100%;
			}
		}
	}

	// padding-bottom: 100px;
	padding-top: 60px;
	// height: 100vh;
	// overflow: hidden;
	&::before {
		content: '';
		display: table;
	}

	.top-nav-bar {
		position: fixed;
		top: 0;
		width: 100%;
		height: 60px;
		z-index: 999;
	}

	.scroll-box {
		width: 100%;
		height: auto;
		/*overflow-y: scroll;*/
		// height: calc(100vh - 60px);
		// overflow: auto;
		.content {
			width: 100%;
			// height: calc(100vh - 60px);
			/*overflow-y: scroll;*/

			.list {
				padding-top: 5px;
				padding-bottom: 30px;

				.day-item,
				.video-item,
				.img-item {
					margin-bottom: 6px;
				}

				// 视频
				.video-item {
					width: 100%;
					background: #ffffff;

					.video-top {
						display: flex;
						padding: 15px 29px 11px 17px;
						.video-top-img {
							width: 40px;
							height: 40px;
							border-radius: 50%;
						}
						.video-content {
							.title {
								display: flex;
								align-items: center;
								font-size: 15px;
								font-family: PingFang SC;
								font-weight: bold;
								color: #323232;
								img {
									width: 20px;
									height: 26.5px;
									margin-left: 8px;
									margin-bottom: 10px;
								}
							}

							.context {
								font-size: 14px;
								font-family: PingFang SC;
								font-weight: 500;
								color: #969696;
								line-height: 18px;
							}
						}

						// img {
						//   width: 40px;
						//   height: 40px;
						//   border-radius: 50%;
						//   margin-right: 11px;
						// }
					}

					.video {
						width: 100%;
						height: 210px;
						background-color: #fff;
					}

					.item-footer {
						display: flex;
						justify-content: space-between;
						align-items: center;
						height: 38px;
						padding: 0 20px 0 18px;

						.optionDate {
							font-size: 12px;
							font-family: PingFang SC;
							font-weight: 500;
							color: #969696;
						}

						.clickCount {
							font-size: 12px;
							font-family: PingFang SC;
							font-weight: 500;
							color: #4d5aff;
						}
					}
				}

				// 燃放日常
				.day-item {
					background-color: #fff;
					display: flex;
					padding: 15px 20px 12px 17px;
					.left {
						width: 51px;
						min-width: 51px;
						padding-right: 11px;

						img {
							width: 40px;
							height: 40px;
							border-radius: 50%;
						}
					}

					.right {
						flex: 1;

						.item-content {
							.title {
								display: flex;
								align-items: center;
								font-size: 15px;
								font-family: PingFang SC;
								font-weight: bold;
								color: #323232;
								// margin-bottom: 10px;
								img {
									min-width: 20px;
									height: 26.5px;
									margin-left: 8px;
									margin-bottom: 10px;
								}
							}

							.context {
								padding-bottom: 10px;
								font-size: 14px;
								font-family: PingFang SC;
								font-weight: 500;
								color: #969696;
								line-height: 18px;
							}
						}

						// .video {
						//   // max-width: 158px;
						//   height: 211px;
						//   margin-top: 11px;
						// }

						.item-footer {
							width: 100%;
							display: flex;
							justify-content: space-between;
							align-items: center;
							height: 38px;

							.optionDate {
								font-size: 12px;
								font-family: PingFang SC;
								font-weight: 500;
								color: #969696;
							}

							.clickCount {
								font-size: 12px;
								font-family: PingFang SC;
								font-weight: 500;
								color: #4d5aff;
							}
						}
					}
				}

				// 图片
				.img-item {
					background-color: #fff;
					padding: 15px 20px 14px 19px;
					.top {
						display: flex;
						.left {
							width: 51px;
							min-width: 51px;
							padding-right: 11px;

							img {
								width: 40px;
								height: 40px;
								border-radius: 50%;
							}
						}

						.right {
							// flex: 1;
							// padding-right: 23px;
							.item-content {
								// padding-right: 29px;
								margin-bottom: 11px;
								.title {
									display: flex;
									align-items: center;
									font-size: 15px;
									font-family: PingFang SC;
									font-weight: bold;
									color: #323232;
									// margin-bottom: 10px;
									img {
										min-width: 20px;
										height: 26.5px;
										margin-left: 8px;
										margin-bottom: 10px;
									}
								}

								.context {
									font-size: 14px;
									font-family: PingFang SC;
									font-weight: 500;
									color: #969696;
									line-height: 18px;
									word-break: break-all;
								}
							}

							.img-box {
								display: flex;
								flex-wrap: wrap;
								// width: 286.5px;

								img {
									width: 93px;
									height: 93px;
									margin-bottom: 3px;
									margin-right: 3px;
								}
							}
						}
					}

					.item-footer {
						width: 100%;
						display: flex;
						justify-content: space-between;
						align-items: center;
						height: 38px;

						.optionDate {
							font-size: 12px;
							font-family: PingFang SC;
							font-weight: 500;
							color: #969696;
						}

						.clickCount {
							font-size: 12px;
							font-family: PingFang SC;
							font-weight: 500;
							color: #4d5aff;
						}
					}
				}
			}
		}
	}
	.control-play {
		width: 100%;
		position: absolute;
		bottom: 0;
		height: 30px;
		display: flex;
		align-items: center;
		color: #fff;
		.progress-bar {
			flex: 1;
			margin: 0 8px;
		}
	}
	/deep/.van-slider__button {
		width: 12px;
		height: 12px;
	}
	.video-img {
		width: 30px;
		height: 30px;
		left: 50%;
		top: 50%;
		position: absolute;
		z-index: 999;
		transform: translate(-50%, -50%);
	}
}
</style>
